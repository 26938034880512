/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

import barba from '@barba/core';
import gsap from 'gsap';

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);
Alpine.start();
window.Alpine = Alpine;

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import HeaderCalc from '@js/helpers/HeaderHeightCalc';
import ViewHeightCalc from '@js/helpers/ViewHeightCalc';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import ScrollTo from '@js/modules/ScrollTo';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import ExternalLinks from '@js/modules/ExternalLinks';
import Page from '@js/modules/Page';
import ProgressLine from '@js/modules/ProgressLine';
import VideoAsset from '@js/modules/VideoAsset';

// Pages modules
// import Home from '@js/pages/Home';


// HTML elememt classlist
const HTML_CLASSLIST = document.documentElement.classList;
const BODY_CLASSLIST = document.body.classList;
const PAGE_TRANSITION_SPEED = 0.7;

window.SOMETHING =
    window.SOMETHING ||
    new (function SOMETHING() {
        // Utilities
        this.env = env;
        this.utils = utils;

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            ScrollYPos: 0,
            GoToScrollPos: false,
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Appear: Appear,
            AppearParent: AppearParent,
            ExternalLinks: ExternalLinks,
            Page: Page,
            ProgressLine: ProgressLine,
            ScrollTo: ScrollTo,
        };

        // Pages
        this.pages = {
            // home: Home,
        };


        let currentController;
        let currentClasses;
        let currentNameSpace;

        const beforeController = (data, done) => {
            console.log('beforeController');
            done();
        };

        const afterLeaveController = (data, done) => {
            console.log('afterLeaveController');
            done();
        };

        const leaveController = (container, done) => {
            HTML_CLASSLIST.add('_page-loading');
            HTML_CLASSLIST.remove('_page-loaded');

            // Stop scroll
            this.modules.PageScrolling.lock(document.body);

            // Show progress line
            this.modules.ProgressLine.show();

            // Fade out container
            gsap.to(container, {
                duration: PAGE_TRANSITION_SPEED,
                alpha: 0,
            });

            // Leave the current page if exists
            currentController &&
            currentController.leave &&
            currentController.leave();

            done();
        };

        const enterController = (container, firstCall = false) => {
            // Scroll to top
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
            });

            // Enable scrolling
            PageScrolling.unlock(document.body);

            // Modules
            ScrollTo.build();

            if (!firstCall) {
                ProgressLine.hide();
            }

            if (!this.env.reducedMotion) {
                Appear.start();
                AppearParent.start();
            }

            PageScrolling.start();
            PageScrollingManager.update(true);

            // Processes new content, enabling htmx behavior
            if (!firstCall) {
                //htmx.process(document.body);
            }

            // Vars
            const nameSpace = container.getAttribute('data-spa-namespace');
            const pageBgColor = container.getAttribute('data-spa-bg');
            const pageHideHeader = container.getAttribute('data-spa-hide-header');
            const pageHideFooter = container.getAttribute('data-spa-hide-footer');

            // Change page background colour
            BODY_CLASSLIST.remove('bg-is-white', 'bg-is-black');

            if (pageBgColor == 'black') {
                // Update meta tag
                document.getElementsByTagName('meta')["theme-color"].content = '#000000';

                // Update body class
                BODY_CLASSLIST.add('bg-is-black');
            }
            else {
                // Update meta tag
                document.getElementsByTagName('meta')["theme-color"].content = '#ffffff';

                // Update body class
                BODY_CLASSLIST.add('bg-is-white');
            }

            // Hide/Show header and footer
            const siteHeader = document.querySelector('.mn-hdr');
            const siteFooter = document.querySelector('.mn-ftr');

            if (siteHeader) {
                if (pageHideHeader == 1) {
                    siteHeader.classList.add('hidden');
                } else {
                    siteHeader.classList.remove('hidden');
                }
            }
            if (siteFooter) {
                if (pageHideFooter == 1) {
                    siteFooter.classList.add('hidden');
                } else {
                    siteFooter.classList.remove('hidden');
                }
            }

            // Page controller
            const pageController = this.pages[nameSpace.toLowerCase()];
            if (pageController) {
                // Run page JS
                currentController = new pageController(container);
            } else {
                currentController = null;
            }

            // Test for old classes - if any, remove them
            if (currentClasses) {
                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.remove(cssClass);
                });
            }
            // Get new classes - if any, add them
            currentClasses = (container.getAttribute('data-spa-class') || null);
            if (currentClasses) {
                currentClasses = currentClasses.split(' ');

                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.add(cssClass);
                });
            }

            currentNameSpace && HTML_CLASSLIST.remove(currentNameSpace);
            currentNameSpace = '_page-' + nameSpace;
            HTML_CLASSLIST.add(currentNameSpace);

            HTML_CLASSLIST.remove('_page-loading');
            HTML_CLASSLIST.add('_page-loaded');

            // Update active links on page
            // Supports sections pages under sections
            Array.from(document.querySelectorAll('a')).forEach((link) => {
                if (
                    this.utils.testHrefIsActual(link.getAttribute('href'))
                    //|| this.utils.testHrefisIncluded(link.getAttribute('href'))
                ) {
                    link.classList.add('_active');
                }
                else {
                    link.classList.remove('_active');
                }
            });

            // Mark external links
            this.helpers.DelayedCall.create(1000, () => {
                this.modules.ExternalLinks.build();
            });

            if (!firstCall) {
                gsap.from(container, {
                    duration: PAGE_TRANSITION_SPEED,
                    alpha: 0
                });
            }
        };

        const afterController = (next) => {
            if (window.SOMETHING.helpers.GoToScrollPos) {
                console.log('APPLY SCROLL');
                console.log(window.SOMETHING.helpers.ScrollYPos);
                window.scrollTo(0, window.SOMETHING.helpers.ScrollYPos);

                window.SOMETHING.helpers.GoToScrollPos = false;
            }

            // Reinit page functionality
            this.modules.Page.init();
        };

        const start = () => {
            const spaContainer = document.body.querySelector('[data-spa-namespace]');

            HTML_CLASSLIST.remove('_page-loading');

            if (spaContainer) {
                enterController(spaContainer, true);
            }

            HTML_CLASSLIST.add('_page-loaded');

            this.modules.Page.init();

            if (spaContainer) {
                /**
                 * Init Barba
                 */
                barba.init({
                    schema: {
                        prefix: 'data-spa',
                    },
                    sync: true,
                    debug: false,
                    prefetchIgnore: true,
                    cacheIgnore: true,
                    preventRunning: true,
                    requestError: (trigger, action, url, response) => {
                        // Go to a custom 404 page if the user click on a link that return a 404 response status
                        if (action === 'click' && response.status && response.status === 404) {
                            barba.go('/404');
                        }

                        // Prevent Barba from redirecting the user to the requested URL
                        // this is equivalent to e.preventDefault() in this context
                        return false;
                    },
                    prevent: ({el, event}) => {
                        event.stopPropagation();

                        console.log(el.getAttribute('data-external'));

                        if (el.getAttribute('data-external') != null || el.getAttribute('target') === '_blank') {
                            console.log('External link');
                            return true;
                        }

                        console.log('Internal link');

                        // Test if link is for a footnote
                        if (el.getAttribute('href').startsWith('#')) {
                            console.log('Anchor link');
                            return true;
                        } else {
                            // Is internal page link - Let Barba JS deal with it
                            event.preventDefault();
                            return false;
                        }
                    },
                    timeout: 30 * 1000,
                    transitions: [{
                        async before(data) {
                            console.log('BEFORE');
                            const done = this.async();

                            beforeController(data, done);
                        },
                        async leave(data) {
                            console.log('LEAVE');
                            const done = this.async();

                            console.log('data');
                            console.log(data);
                            leaveController(data.current.container, done);
                        },
                        async afterLeave(data) {
                            console.log('AFTERLEAVE');
                            const done = this.async();

                            afterLeaveController(data, done);
                        },
                        async enter({current, next}) {
                            console.log('ENTER');

                            enterController(next.container);
                        },
                        async after({next}) {
                            console.log('AFTER');

                            afterController();

                            // GA page view tracking
                            if (typeof gtag === 'function') {
                                gtag('event', 'page_view', {
                                    'page_title': document.title,
                                    'page_location': location.href,
                                    'page_path': location.pathname,
                                });
                            }
                        }
                    }]
                });
            }
        };


        // Window Load
        window.addEventListener('load', e => {
            this.modules.PageScrollingManager.update(true);

            /**
             * Assets - Video
             */
            //VideoAsset.init();
        });

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            // Start SPA
            start();
        });

        // Popstate
        window.addEventListener('popstate', (event) => {
            // Log the state data to the console
            HTML_CLASSLIST.add('_popstate');

            // Enable scrolling
            this.modules.PageScrolling.unlock(document.body);
        });

    })();
